import React from 'react';

function Testimonials() {
    const testimonials = [
        {
            name: 'John Doe',
            title: 'CEO, Example Company',
            image: '/image/auth-01.png',
            text: 'This product has completely transformed the way we operate. Highly recommended!',
        },
        {
            name: 'Jane Smith',
            title: 'Marketing Head, Another Company',
            image: '/image/auth-02.png',
            text: 'An amazing experience! The team was professional, and the product exceeded our expectations.',
        },
        {
            name: 'Alice Johnson',
            title: 'Product Manager, Tech Corp',
            image: '/image/auth-03.png',
            text: 'The features and support have been fantastic. I would recommend this to anyone looking to improve their workflow.',
        },
    ];

    return (
        <section className="py-12 ">
            <div className="mx-auto  ">
                <h2 className="text-3xl font-semibold text-left text-gray-800 mb-6">What Our Clients Say</h2>

                <div className="grid gap-8 md:grid-cols-2 lg:grid-cols-3">
                    {testimonials.map((testimonial, index) => (
                        <div key={index} className="bg-white p-6 rounded-lg shadow-lg">
                            <div className="flex items-center mb-4">
                                <img
                                    src={testimonial.image}
                                    alt={testimonial.name}
                                    className="w-16 h-16 rounded-full mr-4"
                                />
                                <div>
                                    <h3 className="text-lg font-medium text-gray-900">{testimonial.name}</h3>
                                    <p className="text-sm text-gray-500">{testimonial.title}</p>
                                </div>
                            </div>
                            <p className="text-gray-700">{testimonial.text}</p>
                        </div>
                    ))}
                </div>
            </div>
        </section>
    );
}

export default Testimonials;
