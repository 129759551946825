import React, { useState } from "react";
import { useLocation } from "react-router-dom"; // Import useLocation
import Header from "./component/Header";
import Footer from "./component/Footer";
import SignupForm from "./component/express-pay/SignupForm";
import Testimonials from "./component/express-pay/Testimonial";
import OrderForm from "./component/express-pay/OrderForm";

import { Elements } from "@stripe/react-stripe-js";
import { loadStripe } from "@stripe/stripe-js";
import CheckoutForm from "./component/CheckoutForm";
const stripeKey = process.env.REACT_APP_STRIPE_PUBLIC_KEY;
const baseUrl = process.env.REACT_APP_BASE_URL;
const stripePromise = loadStripe(stripeKey);

const ExpressPay = () => {
    const location = useLocation(); // Get the location object
    const queryParams = new URLSearchParams(location.search); // Create URLSearchParams object
    const credit = queryParams.get("credit"); // Get credit param
    const price = queryParams.get("price"); // Get price param
    const currency = queryParams.get("currency"); // Get currency param

    const [email, setEmail] = useState("");

    return (
        <div className="flex flex-col min-h-screen">
            <Header />
            <main className="mx-auto w-3/5 bg-white shadow-lg rounded-lg p-6 sm:p-8 lg:p-10">
                <SignupForm />
                <OrderForm credit={Number(credit)} price={Number(price)} currency={currency} email={email} />
                <Testimonials />
            </main>
            <Footer />
        </div>
    );
};

export default ExpressPay;
