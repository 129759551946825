import { useState, useEffect } from 'react';
import { Elements } from "@stripe/react-stripe-js";
import { loadStripe } from "@stripe/stripe-js";
import axios from "axios";
import CheckoutForm from "../ExpressCheckoutForm";
import PayPalButton from '../PaypalButton';

const stripeKey = process.env.REACT_APP_STRIPE_PUBLIC_KEY;
const baseUrl = process.env.REACT_APP_BASE_URL;
const stripePromise = loadStripe(stripeKey);

function PaymentForm({ credit, price, currency, email }) {
    const [paymentMethod, setPaymentMethod] = useState('creditCard');
    const [showSuccessCredit, setShowSuccessCredit] = useState(false);
    const [showCoupon, setShowCoupon] = useState(false);
    const [couponCode, setCouponCode] = useState("");
    const [discountedPrice, setDiscountedPrice] = useState(null);
    const [couponApplied, setCouponApplied] = useState(false);

    const handleAddCredit = () => {
        axios
            .post(`${baseUrl}/api/credit/buy-credit`, { email, credit })
            .then(() => {
                setShowSuccessCredit(true);
            })
            .catch(() => {
                console.log("network error");
            });
    };

    const handleCouponApply = async () => {
        try {
            const response = await axios.post(`${baseUrl}/api/product/apply-coupon`, {
                couponCode,
            });
            setDiscountedPrice(price * (1 - response.data.discount / 100).toFixed(2));
            setCouponApplied(true);
        } catch (error) {
            console.error("Error applying coupon:", error);
        }
    };

    const handlePaypalPayment = async () => {
        try {
            const res = await axios.post(`${baseUrl}/api/paypal/create-payment`, {
                description: "Payment for credit",
                amount: discountedPrice || price,
                currency,
            });
            // Redirect the user to the PayPal approval URL
            window.location.href = res.data.approvalUrl; // Redirect to PayPal
        } catch (error) {
            console.error("Error creating PayPal payment:", error);
        }
    }

    useEffect(() => {
        fetchProduct();
    }, []);

    const fetchProduct = async () => {
        try {
            const response = await axios.get(
                `${baseUrl}/api/product/coupon-show/${credit}`
            );
            setShowCoupon(response.data);
        } catch (error) {
            console.error("Error fetching product:", error);
        }
    };


    return (
        <div className="x-auto">
            {/* Order Summary Section */}
            <div className="border-b pb-4 mb-4">
                <h2 className="text-2xl md:text-5xl font-semibold text-blue-700 mb-2">Order Summary</h2>
                {showCoupon && !couponApplied && (
                    <div className="w-full md:w-3/4 mx-auto mb-5"> {/* coupon-container */}
                        <div className="flex items-center"> {/* coupon-input-wrapper */}
                            <input
                                type="text"
                                value={couponCode}
                                onChange={(e) => setCouponCode(e.target.value)}
                                placeholder="Enter coupon code"
                                disabled={couponApplied}
                                className="flex-grow p-2 border border-gray-300 rounded-md text-sm" // coupon-input
                            />
                            <button
                                onClick={handleCouponApply}
                                disabled={couponApplied}
                                className="ml-2 p-1 bg-white text-blue-600 border border-blue-600 rounded-md text-sm cursor-pointer transition-all duration-300 ease-in-out hover:bg-blue-100" // coupon-apply-btn
                            >
                                Apply
                            </button>
                        </div>
                    </div>
                )}
                <div className="text-xl md:text-2xl mb-4 flex justify-between items-center">
                    <div className="flex items-center space-x-2">
                        <span className="text-green-600">&#128187;</span> {/* Icon */}
                        <span className="font-medium">{credit === 0 ? 'Free' : `${credit} credit${credit > 1 ? 's' : ''}`}</span>
                    </div>
                    <span className="font-semibold">{price} {currency} </span>
                </div>
                <ul className="text-xl md:text-2xl list-none pl-0">
                    <li className="flex items-center  text-green-600">
                        <span className="mr-2">&#10003;</span> {/* Checkmark Icon */}
                        Get instant access – connect your device in minutes
                    </li>
                    <li className="flex items-center  text-green-600">
                        <span className="mr-2">&#10003;</span> {/* Checkmark Icon */}
                        Your credits never expire!
                    </li>
                    <li className="flex items-center  text-green-600">
                        <span className="mr-2">&#10003;</span> {/* Checkmark Icon */}
                        Connect unlimited devices
                    </li>
                    <li className="flex items-center  text-green-600">
                        <span className="mr-2">&#10003;</span> {/* Checkmark Icon */}
                        Around the clock customer support
                    </li>
                </ul>
            </div>


            {/* Payment Method Selection */}
            <div className="text-xl md:text-2xl flex items-center mb-4">
                <input
                    type="radio"
                    id="creditCard"
                    name="paymentMethod"
                    value="creditCard"
                    checked={paymentMethod === 'creditCard'}
                    onChange={() => setPaymentMethod('creditCard')}
                    className="mr-2"
                />
                <label htmlFor="creditCard" className="font-medium">Credit Card</label>
            </div>
            {paymentMethod === 'creditCard' && (
                <Elements stripe={stripePromise}>
                    <CheckoutForm
                        amount={discountedPrice || price}
                        currency={currency}
                        callBack={handleAddCredit}
                    />
                </Elements>
            )}

            <div className="text-xl md:text-2xl flex items-center my-4">
                <input
                    type="radio"
                    id="paypal"
                    name="paymentMethod"
                    value="paypal"
                    checked={paymentMethod === 'paypal'}
                    onChange={() => setPaymentMethod('paypal')}
                    className="mr-2"
                />
                <label htmlFor="paypal" className="font-medium">PayPal</label>
            </div>
            {paymentMethod === 'paypal' && (
                <PayPalButton />
            )}


            {/* Credit Card Form */}

        </div>
    );
}

export default PaymentForm;
