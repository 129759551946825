const SignupForm = () => {
    return <>
        <h2 className="text-2xl md:text-5xl font-semibold text-blue-700 mb-4 text-center sm:text-left">Create Account</h2>
        <p className="text-lg md:text-2xl font-medium text-gray-600 mb-6 text-center sm:text-left">
            Sign up with email & password to complete the purchase
        </p>

        <form className="flex md:flex-row flex-col gap-4 justify-between md:px-40 px-20">
            <div className=" mb-4 w-1/2 md:w-full">
                <label htmlFor="email" className="block text-sm font-medium text-gray-700">
                    Email
                </label>
                <input
                    type="email"
                    id="email"
                    className="mt-1 block w-full border-gray-300 rounded-md border p-3 shadow-sm focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm"
                    placeholder="Email"
                />
            </div>

            <div className="mb-4  w-1/2 md:w-full">
                <label htmlFor="password" className="block text-sm font-medium text-gray-700">
                    Password
                </label>
                <input
                    type="password"
                    id="password"
                    className="mt-1 block w-full border p-3 border-gray-300 rounded-md shadow-sm focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm"
                    placeholder="Password"
                />
            </div>
        </form>
    </>
};

export default SignupForm;