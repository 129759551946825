import { PayPalScriptProvider, PayPalButtons } from "@paypal/react-paypal-js";

export default function PaypalButton() {
    const initialOptions = {
        clientId: "AbJUHOhFVb1D4cZSacZpSDtT9RI5hW2wXMtJHugY8RcSkgTOchjOIIRLjJOVRUvgm9WIRzfTPDZBHczg",
        currency: "USD", // Set your currency here
        components: "buttons",

        "disable-funding": "paylater,card", // Disable other buttons
    };

    const buttonStyles = {
        layout: "vertical",
        color: "gold",
        shape: "rect",
        label: "paypal",
    };

    return (
        <div>
            <PayPalScriptProvider options={initialOptions}>
                <PayPalButtons
                    style={buttonStyles}
                    createOrder={(data, actions) => {
                        return actions.order.create({
                            purchase_units: [
                                {
                                    amount: {
                                        value: "20.00", // Set your price here
                                    },
                                },
                            ],
                        });
                    }}
                />
            </PayPalScriptProvider>
        </div >
    );
}
